/* eslint-disable @typescript-eslint/no-explicit-any */
/* spell-checker: disable */
export enum MyBrainPlans {
  enthusiast = "Enthusiast",
  powerUser = "Power User",
  professional = "Professional",
  free = "Free",
  enterprise = "Enterprise",
}
export enum BrainDurationType {
  monthly = "month",
  annual = "year",
}

export interface UserData {
  id: string,
  botLimit: number;
  contentFileCount: number;
  contentFileLimit: number;
  freeEmailWelcome: boolean;
  iDKTotalCount: number;
  monthlyContentEmailLimit: boolean;
  monthlyContentEmailWarning: boolean;
  onboardingChecklist: boolean;
  paid: boolean;
  paidEmailWelcomeSeries: boolean;
  planLevel: string;
  questionMonthlyCount: number;
  questionMonthlyCredits: number;
  questionsLastMonthPeriod: number;
  questionsLastMonthPeriods: number[] | null;
  questionsTotalCount: number;
  scheduledJob: boolean;
  settingsOpened: boolean;
  sharedBrain: boolean;
  subscriber: boolean;
  subscriberPeriod: string | null;
  stripeSubscriptionId: string;
  subscriptionStatus: string | null;
  aboutEndUser: string | null;
  hearAboutUs: string | null;
  aboutIndustry: string | null;
  aGPTSignUpI: string | null;
  aGPTSignUpR: string | null;
  aGPTSignUpT: string | null;
  allPurchases: string[] | null;
  apiKey: string;
  bannerTestimonialClosed: boolean;
  emailFirstModel: boolean;
  emailHelpReminder: boolean;
  emailWelcomeSeries: boolean;
  emailConfirmed: boolean;
  eMBEarlyAccess: boolean;
  eMBEarlyPaidAccess: boolean;
  embeddingProjectIds: number[] | null;
  eMBUseCase: string | null;
  firstName: string | null;
  fTEstimate: string | null;
  fTCharacters: number | null;
  fTCredits: number;
  fTEnterprise: boolean;
  fileSize: number;
  fTIds: number[] | null;
  fTModel: string;
  fTModelName: string | null;
  fTNEpochs: number | null;
  fTPaid: boolean;
  fTSubscriber: boolean;
  fTUploadRecords: number | null;
  fullName: string | null;
  googleSignIn: boolean;
  insightReportRequested: number | null;
  language: string | null;
  languagePicture: string | null;
  openAISecretKey: string | null;
  openAIAssistantId: string | null;
  passwordUser: boolean;
  publicId: string | null;
  postAIContent: string | null;
  referralCode: string | null;
  referredCode: string | null;
  referredComplete: boolean;
  selectedLanguage: string;
  source: string | null;
  stripeConsumerId: string;
  student: boolean;
  tweetFamousCredits: number;
  tweetModelFTId: string | null;
  tweetModelFTName: string | null;
  tweetPaidJourney: boolean;
  tweetWriterCreated: boolean;
  tweetWriterCredits: number;
  tweetWriterModelComplete: boolean;
  tweetWriterPaid: boolean;
  tweetsCount: number;
  twitterId: string | null;
  twitterImage: string | null;
  twitterName: string | null;
  twitterOtherAccountPaid: boolean;
  twitterSignIn: boolean;
  twitterUsername: string | null;
  upload: string | null;
  userProject: string | null;
  userProjectDetail: string | null;
  userRole: string | null;
  userTechAbility: string | null;
  whiteLabelApiKey: string | null;
  email: string;
  lastName: string;
  stripeCheckoutSession: string;
  enableSubmitOnEnterPress?: boolean;
}

export interface UserDataLimits {
  isContentExceeded: boolean;
  isQuestionExceeded: boolean;
  isLimitVisible: boolean;
}

export interface VerifyOtpResponse {
  id_token: string;
  access_token: string;
  refresh_token: string;
  scope: string;
  type: string;
}

export interface RefreshTokenResponse {
  accessToken: string;
  idToken: string;
  refreshToken?: string;
}

export interface RequestOtpResponse {
  _id: string;
  email: string;
}
