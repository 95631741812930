export const environment = {
  environmentName: "development",
  apiKey: "098a9f3104ae508bf2fb695abc875fab",
  socketUrl: "https://api.speech.brain-demo.demo.eu.rezolve.com",
  baseUrl: "https://dev.mybrain.zone", //use this for local http://localhost:4200
  googleAnalytics: "",
  allowedFiles: ["pdf", "doc", "docx", "odt", "epub", "pages", "pptx", "xls", "xlsx", "numbers", "csv", "txt"],
  brainHelpId:
    "ydj17-ipoe-rvQVRqJYP-Pvt5PwLsDBvA4vx5g2WiTRKhvlDnFqEtLJ3YK1X06FqzNRX4fJMhMr-tEydSE_fsGb0Z1Ky2wxWU8Bt7oxrESGLztS1fMZFSnr9fhdW0D67",
  pineconeIndexId: "index-my-brain-1",
  pineconeIndexName: "index-my-brain-1", // [TODO] get from database
  isPlanOnSale: false,
  dotNetBaseUrl: "https://brain-assistant-api.dev.eu2.rezolve.com", // when running .net core locally: http://localhost:5001, https://brain-assistant-api.dev.eu2.rezolve.com
  s3EndpointUrl: "https://engagement.dev.eu2.rezolve.com/file/upload",
  featureFlags: {
    allowBrainLLMSelect: true,
  },
};
